import { createSelector, selector, useSelector } from 'store/utils';
import { Product } from 'dto/product';

export const selectProductsFilter = selector((state) => state.product.filter);
export const productsFilterSelector = createSelector(selectProductsFilter);

export const selectProducts = selector((state) => state.product.list);
export const productsSelector = createSelector(selectProducts);

export const selectCurrentProduct = selector((state) => state.product.current);
export const currentProductSelector = createSelector(selectCurrentProduct);

export const useProductSelector = <T extends Product>(
  typeGuard: (product?: Product) => product is T
): T | undefined => {
  const product = useSelector(currentProductSelector);

  return typeGuard(product) ? product : undefined;
};

export const selectProductCategories = selector(
  (state) => state.product.categories
);
export const productCategoriesSelector = createSelector(
  selectProductCategories
);

export const selectProductCategoryMapping = selector(
  (state) => state.product.categoryMapping
);
export const productCategoryMappingSelector = createSelector(
  selectProductCategoryMapping
);
