import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransHeader = createTrans({
  ancillaryTypes: (
    <Trans i18nKey="header.ancillaryTypes" defaults="Ancillary types" />
  ),
  productCategory: (
    <Trans i18nKey="header.productCategory" defaults="Product category" />
  ),
  products: <Trans i18nKey="header.products" defaults="Products" />,
  tariff: <Trans i18nKey="header.tariff" defaults="Tariff" />,
});
