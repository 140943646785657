import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTitle = createTrans({
  aftersales: <Trans i18nKey="title.aftersales" defaults="Aftersales" />,
  ancillaryTypes: (
    <Trans i18nKey="title.ancillaryTypes" defaults="Ancillary types" />
  ),
  category: <Trans i18nKey="title.category" defaults="Category" />,
  description: <Trans i18nKey="title.description" defaults="Description" />,
  group: <Trans i18nKey="title.group" defaults="Group" />,
  organization: <Trans i18nKey="header.organization" defaults="Organization" />,
  owner: <Trans i18nKey="title.owner" defaults="Owner" />,
  productCategory: (
    <Trans i18nKey="header.productCategory" defaults="Product category" />
  ),
  products: <Trans i18nKey="title.products" defaults="Products" />,
  selectStop: <Trans i18nKey="title.selectStop" defaults="Select stop" />,
  subCategory: <Trans i18nKey="title.subCategory" defaults="Sub category" />,
  subGroup: <Trans i18nKey="title.subGroup" defaults="Sub group" />,
});
