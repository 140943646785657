import { Button, Loadable, Modal, Table, TableColumns } from '@fleet/shared';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { CardContent, Divider, Radio, Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { StopSearchForm } from 'routes/tariff/tariffFormAccordion/supplements/StopSearchForm';
import { TransTableHead } from 'i18n/trans/table';
import { Row, usePagination, useTable } from 'react-table';
import { Pagination, PaginationParams } from '@fleet/shared/dto/pagination';
import { Stop, StopSearchFilter } from 'dto/stop';
import { Classifier } from '@fleet/shared/dto/classifier';
import { Icon } from '@fleet/shared/mui';
import { fetchStops } from 'features/tariff/tariffService';

interface StopSelectionModalProps {
  stopData?: { rowId: string; stopKey: string };
  onSubmit: (selectedStop: Classifier) => void;
  onClose: () => void;
}

export const StopSelectionModal: FC<StopSelectionModalProps> = ({
  stopData,
  onSubmit,
  onClose,
}) => {
  const [data, setData] = useState<Pagination<Stop> | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStop, setSelectedStop] = useState<Classifier | undefined>();
  const [paginationParams, setPaginationParams] = useState<
    PaginationParams | undefined
  >();
  const [filter, setFilter] = useState<StopSearchFilter | undefined>();

  const fetchData = useCallback(
    async (filter: StopSearchFilter | PaginationParams) => {
      setIsLoading(true);
      setData(await fetchStops(filter));
      setIsLoading(false);
    },
    []
  );

  useEffect(() => {
    if (!stopData) {
      setSelectedStop(undefined);
      setData(undefined);
      setPaginationParams(undefined);
    }
  }, [stopData]);

  useEffect(() => {
    if (filter || paginationParams) {
      fetchData({ ...filter, ...paginationParams });
    }
  }, [fetchData, filter, paginationParams]);

  const columns = useMemo<TableColumns<Stop>>(
    () => [
      {
        id: 'selection',
        width: 40,
        Cell: ({ row }: { row: Row<Stop> }) => (
          <Radio
            onClick={() =>
              setSelectedStop({ id: row.id, name: row.original.name })
            }
            size="small"
            checked={row.id === selectedStop?.id}
          />
        ),
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
      },
      {
        accessor: 'countryName',
        Header: <TransTableHead i18nKey="country" />,
      },
      {
        accessor: 'countyName',
        Header: <TransTableHead i18nKey="county" />,
      },
      {
        accessor: 'cityName',
        Header: <TransTableHead i18nKey="city" />,
      },
      {
        accessor: 'streetAddress',
        Header: <TransTableHead i18nKey="streetHouseNumber" />,
      },
    ],
    [selectedStop]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      setPaginationParams(paginationParams),
    []
  );

  const getRowId = useCallback((row) => row.id!, []);

  const table = useTable<Stop>(
    {
      data: useMemo(() => data?.items ?? [], [data?.items]),
      columns,
      pageCount: -1,
      total: data?.totalCount,
      manualPagination: true,
      onPageChange: handlePageChange,
      getRowId,
    },
    usePagination
  );

  return (
    <Modal
      open={!!stopData}
      onClose={onClose}
      title={<TransTitle i18nKey="selectStop" />}
      actionButton={
        <Button
          variant="contained"
          type="submit"
          onClick={() => onSubmit(selectedStop!)}
          startIcon={<Icon name="check" size={20} />}
          disabled={!selectedStop}
        >
          <TransButton i18nKey="confirm" />
        </Button>
      }
      maxWidth="md"
      fullWidth
    >
      <Loadable loading={isLoading}>
        <Table
          caption={
            <>
              <StopSearchForm
                onFilterSubmit={(filter) => {
                  setFilter(filter);
                  setSelectedStop(undefined);
                }}
              />
              <Divider sx={{ pt: '24px' }} />
              <CardContent sx={{ pl: '0' }}>
                <Stack direction="row" alignItems="baseline" spacing={1}>
                  <Typography variant="subtitle" fontWeight={700}>
                    <TransSubtitle i18nKey="searchResults" />
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <TransSubtitle
                      i18nKey="stopsQty"
                      values={{ num: data?.totalCount || 0 }}
                    />
                  </Typography>
                </Stack>
              </CardContent>
            </>
          }
          table={table}
        />
      </Loadable>
    </Modal>
  );
};
