import { createSelector, selector } from 'store/utils';

export const selectAncillaryTypes = selector(
  (state) => state.ancillaryTypes.list
);
export const ancillaryTypesSelector = createSelector(selectAncillaryTypes);

export const selectAncillaryType = selector(
  (state) => state.ancillaryTypes.current
);
export const ancillaryTypeSelector = createSelector(selectAncillaryType);
