import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { AncillaryType, AncillaryTypeDetailed } from 'dto/ancillaryTypes';
import {
  createAncillaryType,
  getFullAncillaryType,
  getAncillaryTypes,
  reorderAncillaryTypeSubtypes,
  reorderAncillaryTypes,
  setAncillaryType,
  setAncillaryTypes,
  updateAncillaryType,
  updateOrCreateAncillaryTypeLocalizations,
  updateOrCreateAncillaryTypeSubtypeLocalizations,
  updateOrCreateAncillaryTypeSubtypes,
  getAncillaryType,
  getAncillaryTypeSubtypes,
} from 'features/ancillaryTypes/ancillaryTypesActions';

export interface AncillaryTypesState {
  list: Array<AncillaryType>;
  current?: AncillaryTypeDetailed;
}

const initialState: AncillaryTypesState = {
  list: [],
};

export const ancillaryTypesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getFullAncillaryType.fulfilled, (state, action) => {
      state.current = action.payload;
    })
    .addMatcher(
      (action): action is PayloadAction<Array<AncillaryType>> =>
        [
          getAncillaryTypes.fulfilled.type,
          setAncillaryTypes.type,
          reorderAncillaryTypes.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        state.list = action.payload;
      }
    )
    .addMatcher(
      (
        action
      ): action is PayloadAction<Partial<AncillaryTypeDetailed> | undefined> =>
        [
          getAncillaryType.fulfilled.type,
          getAncillaryTypeSubtypes.fulfilled.type,
          setAncillaryType.type,
          createAncillaryType.fulfilled.type,
          updateAncillaryType.fulfilled.type,
          updateOrCreateAncillaryTypeLocalizations.fulfilled.type,
          updateOrCreateAncillaryTypeSubtypes.fulfilled.type,
          reorderAncillaryTypeSubtypes.fulfilled.type,
          updateOrCreateAncillaryTypeSubtypeLocalizations.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        if (!action.payload) {
          state.current = undefined;
        }

        if (state.current) {
          state.current = {
            ...state.current,
            ...action.payload,
          };
        }
      }
    );
});
