import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTooltip = createTrans({
  inventoryClassLimit: (
    <Trans
      i18nKey="tooltip.inventoryClassLimit"
      defaults="The number of inventory classes selected cannot exceed the number of members in the co-taxation group"
    />
  ),
});
