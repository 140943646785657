import { createReducer } from '@reduxjs/toolkit';
import {
  ProductCategory,
  ProductCategoryMapping,
  ProductCategorySearchFilter,
} from 'dto/productCategory';
import {
  clearProductCategoryList,
  getProductCategoryById,
  getProductCategoryList,
  getProductCategoryMappings,
  setProductCategory,
  setProductCategoryFilter,
} from 'features/productCategory/productCategoryActions';

interface ProductCategoryState {
  list: Array<ProductCategory>;
  current?: ProductCategory;
  categoryMapping: Array<ProductCategoryMapping>;
  filter: Partial<ProductCategorySearchFilter>;
}

const initialState: ProductCategoryState = {
  list: [],
  categoryMapping: [],
  filter: {},
};

export const productCategoryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearProductCategoryList, (state) => {
      state.list = [];
    })
    .addCase(getProductCategoryList.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(setProductCategory, (state, action) => {
      state.current = action.payload;
    })
    .addCase(getProductCategoryById.fulfilled, (state, action) => {
      state.current = action.payload;
    })
    .addCase(setProductCategoryFilter, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(getProductCategoryMappings.fulfilled, (state, action) => {
      state.categoryMapping = action.payload;
    });
});
