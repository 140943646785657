import { createAsyncThunk } from 'store/utils';
import {
  ProductCategoryMapping,
  ProductCategorySearchFilter,
  ProductCategory,
} from 'dto/productCategory';
import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';
import { api } from '@fleet/shared';

export const setProductCategoryFilter = createAction<
  Partial<ProductCategorySearchFilter>
>('productCategory/setProductCategoryFilter');

export const clearProductCategoryList = createAction(
  'productCategory/clearProductCategoryList'
);

export const getProductCategoryList = createAsyncThunk<
  Array<ProductCategory>,
  Partial<ProductCategorySearchFilter> | undefined
>('productCategory/getProductCategoryList', async (filter, { dispatch }) => {
  filter && dispatch(setProductCategoryFilter(filter));
  return (
    await api.get(
      `/product-categories${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`
    )
  ).data.items;
});

export const setProductCategory = createAction<ProductCategory | undefined>(
  'productCategory/setProductCategory'
);

export const getProductCategoryById = createAsyncThunk<ProductCategory, string>(
  'productCategory/getProductCategoryById',
  async (id) => {
    return (await api.get(`/product-categories/${id}`)).data;
  }
);

export const updateProductCategory = createAsyncThunk<
  ProductCategory,
  { id: string }
>(
  'productCategory/updateProductCategory',
  async ({ id, ...payload }, { rejectWithValue }) => {
    try {
      return (await api.put(`/product-categories/${id}`, payload)).data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

export const createProductCategory = createAsyncThunk<ProductCategory, object>(
  'productCategory/createProductCategory',
  async ({ ...payload }, { rejectWithValue }) => {
    try {
      return (await api.post(`/product-categories`, payload)).data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getProductCategoryMappings = createAsyncThunk<
  Array<ProductCategoryMapping>
>(
  'productCategory/getProductCategoryMappings',
  async () => (await api.get(`/product-categories/mappings`)).data.items
);
