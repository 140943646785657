import { FC, Fragment } from 'react';
import { DateField } from '@fleet/shared/form';
import { Button, Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { Grid, IconButton } from '@mui/material';
import { FieldArray } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { currentLocaleConfiguration } from '@fleet/shared/i18n';

export const TravelTimePeriods: FC = () => (
  <FieldArray name="travelTimePeriods">
    {({ fields }) => (
      <>
        {fields.map((name, index) => (
          <Fragment key={name}>
            <Grid item xs={1}>
              <DateField
                label={<TransField i18nKey="from" />}
                name={`${name}.from`}
                showTimeSelect
                showTimeSelectOnly
                dateFormat={currentLocaleConfiguration.timeFormat}
                timeFormat={currentLocaleConfiguration.timeFormat}
                timeIntervals={1}
              />
            </Grid>
            <Grid item xs={1}>
              <DateField
                label={<TransField i18nKey="to" />}
                name={`${name}.to`}
                showTimeSelect
                showTimeSelectOnly
                dateFormat={currentLocaleConfiguration.timeFormat}
                timeFormat={currentLocaleConfiguration.timeFormat}
                timeIntervals={1}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => fields.remove(index)} sx={{ mt: 2.5 }}>
                <Icon name="trash" size={24} />
              </IconButton>
            </Grid>
            <Grid item xs={1} />
          </Fragment>
        ))}
        <Button
          variant="text"
          color="primary"
          sx={{ mt: 1 }}
          startIcon={<Icon name="plus" />}
          onClick={() => fields.push({ from: '', to: '' })}
          label={<TransButton i18nKey="addNewTimePeriod" />}
        />
      </>
    )}
  </FieldArray>
);
