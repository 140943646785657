import { FC } from 'react';
import { TimeDurationField } from '@fleet/shared';
import { Grid } from '@mui/material';
import { TimeUnit } from '@fleet/shared/utils/timeDuration';

export const OpenUntilMinutesBeforeDeparture: FC = () => (
  <Grid item xs={1}>
    <TimeDurationField
      name="timeDurationOpenUntilBeforeDeparture"
      excludedUnits={[TimeUnit.SECONDS]}
    />
  </Grid>
);
