import { AncillaryType, AncillaryTypeSubtype } from 'dto/ancillaryTypes';
import { api } from '@fleet/shared';

export const fetchAncillaryType = async (id: AncillaryType['id']) =>
  (await api.get<AncillaryType>(`/ancillary-types/${id}`)).data;

export const fetchAncillaryTypeSubtypes = async (id: AncillaryType['id']) =>
  (
    await api.get<{ items: Array<AncillaryTypeSubtype> }>(
      `/ancillary-types/${id}/ancillary-subtypes`
    )
  ).data.items;
