import { createSelector, selector } from 'store/utils';

export const selectProductCategoryList = selector(
  (state) => state.productCategory.list
);
export const productCategoryListSelector = createSelector(selectProductCategoryList);

export const selectCurrentProductCategory = selector(
  (state) => state.productCategory.current
);
export const currentProductCategorySelector = createSelector(selectCurrentProductCategory);

export const selectProductCategoryMappings = selector(
  (state) => state.productCategory.categoryMapping
);
export const productCategoryMappingsSelector = createSelector(
  selectProductCategoryMappings
);

export const selectProductCategoryFilter = selector(
  (state) => state.productCategory.filter
);
export const productCategoryFilterSelector = createSelector(
  selectProductCategoryFilter
);
