import {
  makeClassificationOptions,
  SelectField,
  useField,
  useFormContext,
} from '@fleet/shared';
import { Option } from '@fleet/shared/dto/option';
import { Grid } from '@mui/material';
import { Classifier } from '@fleet/shared/dto/classifier';
import { ProductAncillaryPayload } from 'dto/product';
import { ancillaryTypesSelector } from 'features/ancillaryTypes/ancillaryTypesSelectors';
import { fetchAncillaryTypeSubtypes } from 'features/ancillaryTypes/ancillaryTypesService';
import { TransField } from 'i18n/trans/field';
import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'store/utils';

interface AncillaryTypesFieldProps {}

export const AncillaryTypesField: FC<AncillaryTypesFieldProps> = () => {
  const { change } = useFormContext<ProductAncillaryPayload>();
  const {
    input: { value: ancillaryTypeId },
  } = useField('ancillaryTypeId', {
    subscription: { value: true },
  });

  const ancillaryTypes = useSelector(ancillaryTypesSelector);
  const ancillaryTypesOptions = useMemo(
    () => makeClassificationOptions(ancillaryTypes),
    [ancillaryTypes]
  );

  const [ancillarySubtypesOptions, setAncillarySubtypesOptions] = useState<
    Array<Option<string>>
  >([]);

  const handleAncillaryTypeChanged = useCallback(
    async (id: string | undefined) => {
      change('ancillarySubtypeId');

      const options = id
        ? makeClassificationOptions<Classifier>(
            await fetchAncillaryTypeSubtypes(id)
          )
        : [];

      setAncillarySubtypesOptions(options);
    },
    [change]
  );

  return (
    <>
      <Grid item xs={1}>
        <SelectField
          name="ancillaryTypeId"
          label={<TransField i18nKey="ancillaryType" />}
          options={ancillaryTypesOptions}
          onChange={handleAncillaryTypeChanged}
          showEmptyOption
        />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          name="ancillarySubtypeId"
          label={<TransField i18nKey="ancillarySubtype" />}
          options={ancillarySubtypesOptions}
          showEmptyOption
          disabled={!ancillaryTypeId}
        />
      </Grid>
    </>
  );
};
