import { combineReducers } from '@reduxjs/toolkit';
import { loadingReducer } from 'features/loading/loadingReducer';
import { commonReducer } from 'features/common/commonReducer';
import { classificationReducer } from 'features/classification/classificationReducer';
import { productCategoryReducer } from 'features/productCategory/productCategoryReducer';
import { productReducer } from 'features/product/productReducer';
import { tariffReducer } from 'features/tariff/tariffReducer';
import { ancillaryTypesReducer } from 'features/ancillaryTypes/ancillaryTypesReducer';

export default combineReducers({
  loading: loadingReducer,
  common: commonReducer,
  classification: classificationReducer,
  productCategory: productCategoryReducer,
  product: productReducer,
  tariff: tariffReducer,
  ancillaryTypes: ancillaryTypesReducer,
});
