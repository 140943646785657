import { FC, Fragment } from 'react';
import { Button, Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { Grid, IconButton } from '@mui/material';
import {
  FieldArray,
  PeriodField,
  RadioGroupField,
  TextField,
  useFormContext,
} from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TimeDependentConditionsValues } from 'dto/tariff';

export const TravelDatePeriods: FC = () => {
  const form = useFormContext<TimeDependentConditionsValues>();
  const values = form.getState().values;
  const travelPeriodTypeOptions = useClassificationOptions(
    ClassificationGroup.TRAVEL_PERIOD_TYPE
  );

  return (
    <>
      <Grid item xs={1}>
        <RadioGroupField
          name="travelPeriodTypeId"
          label={<TransField i18nKey="travelPeriodType" />}
          onChange={({ target }) => {
            if (target.value === 'TRAVEL_PERIOD_TYPE.FIXED') {
              form.change('numberOfRollingDays');
            } else if (target.value === 'TRAVEL_PERIOD_TYPE.ROLLING') {
              form.change('travelDatePeriods', []);
            }
          }}
          options={travelPeriodTypeOptions}
          inline
        />
      </Grid>
      <Grid item xs={1}>
        {values.travelPeriodTypeId === 'TRAVEL_PERIOD_TYPE.ROLLING' && (
          <TextField
            label={<TransField i18nKey="rollingTravelPeriod" />}
            name="numberOfRollingDays"
            required
          />
        )}
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={1} />
      <FieldArray name="travelDatePeriods">
        {({ fields }) => (
          <>
            {fields.map((name, index) => (
              <Fragment key={name}>
                <PeriodField
                  from={{
                    name: `${name}.from`,
                    label: <TransField i18nKey="from" />,
                    isClearable: true,
                  }}
                  to={{
                    name: `${name}.to`,
                    label: <TransField i18nKey="to" />,
                    isClearable: true,
                  }}
                />
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => fields.remove(index)}
                    sx={{ mt: 2.5 }}
                  >
                    <Icon name="trash" size={24} />
                  </IconButton>
                </Grid>
                <Grid item xs={1} />
              </Fragment>
            ))}
            {values.travelPeriodTypeId === 'TRAVEL_PERIOD_TYPE.FIXED' && (
              <Button
                variant="text"
                color="primary"
                sx={{ mt: 1 }}
                startIcon={<Icon name="plus" />}
                onClick={() => fields.push({ from: '', to: '' })}
                label={<TransButton i18nKey="addNewDatePeriod" />}
              />
            )}
          </>
        )}
      </FieldArray>
    </>
  );
};
